import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Particle from "../../Particle";

function PostgresMigration() {
  const navigate = useNavigate();

  return (
    <Container fluid className="project-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={8}>
            <h1 className="project-heading" style={{ marginBottom: "30px" }}>
              Migration to <strong className="purple">PostgreSQL</strong>
            </h1>
            <div style={{ color: "white", textAlign: "justify" }}>
              <p style={{ marginBottom: "20px" }}>
                The company I worked for had three PostgreSQL clusters, one for each environment: development, pre-production, and production. These clusters were on legacy servers, and we aimed to move them to a database-as-a-service provided by the company. Additionally, we upgraded PostgreSQL to version 14 and restructured the service account management.
              </p>
              
              <h2 className="purple" style={{ marginBottom: "20px" }}>Challenges</h2>
              <p style={{ marginBottom: "20px" }}>
                The migration involved several critical steps:
              </p>

              <h3 style={{ marginBottom: "15px" }}>1. Inventory of Schemas and Tables</h3>
              <p style={{ marginBottom: "20px" }}>
                Conducted a thorough inventory of all schemas and tables to prepare for the migration. This included:
              </p>
              <ul>
                <li><strong>Schema Documentation:</strong> Detailed documentation of existing schemas and tables to understand the current state and dependencies.</li>
                <li><strong>Data Volume Assessment:</strong> Evaluated the size and volume of data to be migrated to plan for storage and transfer requirements.</li>
                <li><strong>Compatibility Checks:</strong> Ensured compatibility with PostgreSQL 14 to avoid issues during the migration process.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>2. Service Account Creation</h3>
              <p style={{ marginBottom: "20px" }}>
                Created individual service accounts with limited privileges for each service, adhering to the principle of least privilege. Steps included:
              </p>
              <ul>
                <li><strong>Account Segmentation:</strong> Created separate accounts for different services to isolate access and enhance security.</li>
                <li><strong>Privilege Assignment:</strong> Assigned minimal necessary privileges to each account to reduce potential attack surfaces.</li>
                <li><strong>Audit Logging:</strong> Implemented logging to monitor account usage and detect any unauthorized access attempts.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>3. Collaboration with Development Team</h3>
              <p style={{ marginBottom: "20px" }}>
                Worked closely with the development team to update the accounts used by each service. This collaboration involved:
              </p>
              <ul>
                <li><strong>Requirement Gathering:</strong> Collected requirements from developers regarding database access needs and constraints.</li>
                <li><strong>Account Transition Plan:</strong> Developed a plan to transition services to the new accounts without downtime.</li>
                <li><strong>Testing and Validation:</strong> Conducted tests with the development team to ensure seamless transition and functionality.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>4. Scripting for Automation</h3>
              <p style={{ marginBottom: "20px" }}>
                Developed scripts to automate the creation of service accounts and the update of Vault variables for database endpoints. This included:
              </p>
              <ul>
                <li><strong>Script Development:</strong> Wrote scripts to automate repetitive tasks such as account creation and endpoint updates.</li>
                <li><strong>Version Control:</strong> Used version control systems to manage and track script changes.</li>
                <li><strong>Integration Testing:</strong> Tested scripts in a staging environment to ensure they worked as intended before deployment.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>5. Infrastructure Setup</h3>
              <p style={{ marginBottom: "20px" }}>
                Collaborated with the infrastructure team to set up target databases. Key activities included:
              </p>
              <ul>
                <li><strong>Provisioning Resources:</strong> Allocated necessary resources for the new PostgreSQL clusters in the database-as-a-service platform.</li>
                <li><strong>Configuration Management:</strong> Applied configuration settings to optimize performance and security for the new environment.</li>
                <li><strong>Replication Setup:</strong> Configured replication between old and new databases to ensure data consistency during migration.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>6. Scheduled Migration</h3>
              <p style={{ marginBottom: "20px" }}>
                Coordinated with the development team to schedule and execute the migration. This process involved:
              </p>
              <ul>
                <li><strong>Migration Plan:</strong> Developed a detailed migration plan outlining steps, timelines, and rollback procedures.</li>
                <li><strong>Downtime Minimization:</strong> Strategized to minimize downtime during the migration, ensuring business continuity.</li>
                <li><strong>Communication:</strong> Maintained clear communication with stakeholders throughout the migration process to manage expectations and updates.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>7. Database Endpoint Update</h3>
              <p style={{ marginBottom: "20px" }}>
                Modified the database endpoint variables for each service using the developed scripts. This step included:
              </p>
              <ul>
                <li><strong>Endpoint Configuration:</strong> Updated service configurations to point to the new database endpoints.</li>
                <li><strong>Script Execution:</strong> Ran the automation scripts to apply the endpoint changes across all services.</li>
                <li><strong>Validation:</strong> Verified that all services were correctly connected to the new databases and functioning as expected.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>8. Integrity Checks</h3>
              <p style={{ marginBottom: "30px" }}>
                Conducted integrity checks to ensure data accuracy and proper access for each service post-migration. This involved:
              </p>
              <ul>
                <li><strong>Data Validation:</strong> Verified data accuracy and integrity by comparing pre- and post-migration data states.</li>
                <li><strong>Access Testing:</strong> Ensured that all services had the necessary access permissions and were functioning correctly.</li>
                <li><strong>Performance Monitoring:</strong> Monitored database performance post-migration to identify and resolve any issues promptly.</li>
              </ul>

              <h2 className="purple" style={{ marginBottom: "20px" }}>Outcome</h2>
              <p style={{ marginBottom: "30px" }}>
                This migration project significantly improved the company's database infrastructure by transitioning to a more modern and secure setup. The successful completion of this project resulted in enhanced performance, security, and maintainability of the PostgreSQL clusters.
              </p>
            </div>
            <Button variant="primary" onClick={() => navigate(-1)} style={{ marginTop: "20px" }}>
              Back to Work
            </Button>
          </Col>
        </Row>
      </Container>
      <Particle />
    </Container>
  );
}

export default PostgresMigration;
