import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Particle from "../../Particle";

function KubeMigration() {
  const navigate = useNavigate();

  return (
    <Container fluid className="project-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={8}>
            <h1 className="project-heading" style={{ marginBottom: "30px" }}>
              Migration to <strong className="purple">Kubernetes</strong>
            </h1>
            <div style={{ color: "white", textAlign: "justify" }}>
              <p style={{ marginBottom: "20px" }}>
                When I joined the company, they were using Docker Swarm, which was not running smoothly. Containers were crashing inexplicably, and inter-container communication was inconsistent. Given the business implications, the company decided to transition to Kubernetes on new clusters. This involved converting Docker Swarm configurations into Kubernetes manifest files.
              </p>
              
              <h2 className="purple" style={{ marginBottom: "20px" }}>Challenges</h2>
              <p style={{ marginBottom: "20px" }}>
                Addressing these issues involved several steps:
              </p>

              <h3 style={{ marginBottom: "15px" }}>1. Converted Docker Swarm Configurations</h3>
              <p style={{ marginBottom: "20px" }}>
                Converted existing Docker Swarm configurations into Kubernetes manifest files, ensuring a smooth transition. This process included:
              </p>
              <ul>
                <li><strong>Config File Conversion:</strong> Translated Docker Compose files into Kubernetes manifests, including deployments, services, and config maps.</li>
                <li><strong>Validation:</strong> Tested converted configurations in a staging environment to ensure correctness and functionality.</li>
                <li><strong>Documentation:</strong> Documented the conversion process and created a mapping guide for future reference.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>2. Service Migration</h3>
              <p style={{ marginBottom: "20px" }}>
                Managed the migration of several services, ensuring minimal disruption and optimal performance. Key activities included:
              </p>
              <ul>
                <li><strong>Service Dependency Mapping:</strong> Identified and documented dependencies between services to plan the migration order.</li>
                <li><strong>Migration Plan:</strong> Developed a detailed migration plan, including timelines, testing procedures, and rollback strategies.</li>
                <li><strong>Execution:</strong> Coordinated with development and operations teams to execute the migration with minimal downtime.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>3. Training Sessions</h3>
              <p style={{ marginBottom: "20px" }}>
                Conducted training sessions for product teams on Kubernetes usage, including accessing clusters, troubleshooting failing pods, and retrieving logs. This involved:
              </p>
              <ul>
                <li><strong>Kubernetes Basics:</strong> Provided an overview of Kubernetes concepts such as pods, services, deployments, and namespaces.</li>
                <li><strong>Hands-On Workshops:</strong> Organized practical workshops to demonstrate accessing clusters, deploying applications, and using kubectl commands.</li>
                <li><strong>Debugging Techniques:</strong> Taught teams how to troubleshoot common issues, retrieve logs, and debug failing pods.</li>
                <li><strong>Resource Management:</strong> Explained how to manage resource requests and limits to optimize application performance and cost.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>4. Resource Optimization</h3>
              <p style={{ marginBottom: "20px" }}>
                Optimized request and limit resources for each service to reduce platform costs and enhance performance. This included:
              </p>
              <ul>
                <li><strong>Resource Analysis:</strong> Analyzed the resource usage of each service to determine appropriate requests and limits.</li>
                <li><strong>Configuration Updates:</strong> Updated Kubernetes manifests to specify optimized resource requests and limits for CPU and memory.</li>
                <li><strong>Monitoring:</strong> Implemented monitoring tools to track resource usage and adjust configurations as needed.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>5. Disaster Recovery Exercise</h3>
              <p style={{ marginBottom: "20px" }}>
                Performed a successful disaster recovery exercise to ensure system resilience and preparedness. Key steps included:
              </p>
              <ul>
                <li><strong>Disaster Scenario Planning:</strong> Defined potential disaster scenarios and their impact on the infrastructure.</li>
                <li><strong>Recovery Procedures:</strong> Developed and documented recovery procedures for each scenario, including backup and restore processes.</li>
                <li><strong>Exercise Execution:</strong> Conducted the disaster recovery exercise to validate procedures and identify areas for improvement.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>6. Legacy Service Migration</h3>
              <p style={{ marginBottom: "20px" }}>
                Assisted product owner teams in migrating legacy services to Kubernetes, including defining manifests, Dockerfiles, and entry points. This involved:
              </p>
              <ul>
                <li><strong>Service Assessment:</strong> Evaluated legacy services to determine migration requirements and dependencies.</li>
                <li><strong>Manifest Creation:</strong> Created Kubernetes manifests for legacy services, defining deployments, services, and configurations.</li>
                <li><strong>Dockerfile Development:</strong> Developed Dockerfiles for legacy services to containerize applications and streamline deployment.</li>
                <li><strong>Entry Point Configuration:</strong> Configured entry points and startup scripts to ensure services ran correctly in the new environment.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>7. Team Meetings and Training</h3>
              <p style={{ marginBottom: "30px" }}>
                Held regular meetings with all development teams to teach them how to use Kubernetes, perform basic debugging, and manage resources. This included:
              </p>
              <ul>
                <li><strong>Regular Check-Ins:</strong> Scheduled weekly meetings to discuss progress, address challenges, and provide guidance on Kubernetes best practices.</li>
                <li><strong>Interactive Training:</strong> Conducted interactive training sessions to demonstrate practical Kubernetes tasks and answer questions.</li>
                <li><strong>Knowledge Sharing:</strong> Created a knowledge-sharing platform for teams to share tips, scripts, and solutions for common issues.</li>
              </ul>

              <h2 className="purple" style={{ marginBottom: "20px" }}>Outcome</h2>
              <p style={{ marginBottom: "30px" }}>
                This migration to Kubernetes resolved numerous infrastructure issues, improved system stability, and enhanced performance. The transition enabled the company to leverage Kubernetes' robust orchestration capabilities, leading to a more resilient and efficient infrastructure.
              </p>
            </div>
            <Button variant="primary" onClick={() => navigate(-1)} style={{ marginTop: "20px" }}>
              Back to Work
            </Button>
          </Col>
        </Row>
      </Container>
      <Particle />
    </Container>
  );
}

export default KubeMigration;
