import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Work from "./components/Work/Work";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import ElasticSecurity from "./components/Work/Articles/ElasticSecurity";
import PostgresMigration from "./components/Work/Articles/PostgresMigration";
import StellaProject from "./components/Work/Articles/StellaProject";
import KubeMigration from "./components/Work/Articles/KubeMigration";
import DeployEthereumNode from "./components/Work/Articles/DeployEthereumNode";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project" element={<Projects />} />
            <Route path="/work" element={<Work />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/work/elastic-security" element={<ElasticSecurity />} />
            <Route path="/work/ethereum-node" element={<DeployEthereumNode />} />
            <Route path="/work/postgres-migration" element={<PostgresMigration />} />
            <Route path="/work/stella-project" element={<StellaProject />} />
            <Route path="/work/kube-migration" element={<KubeMigration />} />
            <Route path="*" element={<Navigate to="/"/>} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
