import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import WorkCard from "./WorkCard";
import Particle from "../Particle";
import workImage1 from "../../Assets/Work/elastic_security.png";
import workImage2 from "../../Assets/Work/postgres_migration.png";
import workImage3 from "../../Assets/Work/stella_project.png";
import workImage4 from "../../Assets/Work/kube_migration.png";
import workImage5 from "../../Assets/Work/ethereum.png";

function Work() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Work</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few articles about my previous work experiences, though this is not an exhaustive list.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "30px" }}>
          <Col md={6} lg={4} className="work-card">
            <WorkCard
              imgPath={workImage1}
              title="Securing Elasticsearch"
              description="Secured elasticsearch clusters with SSL and authentication."
              readMoreLink="/work/elastic-security"
              bgColor="#1e212d"
            />
          </Col>
          <Col md={6} lg={4} className="work-card">
            <WorkCard
              imgPath={workImage5}
              title="Setting up Ethereum Node"
              description="Deploying an Ethereum Node on AWS with Kubernetes."
              readMoreLink="/work/ethereum-node"
              bgColor="#1e212d"
            />
          </Col>
          <Col md={6} lg={4} className="work-card">
            <WorkCard
              imgPath={workImage2}
              title="PostgreSQL Upgrade"
              description="Migrated PostgreSQL clusters and upgraded versions with security improvements."
              readMoreLink="/work/postgres-migration"
              bgColor="#2d1e21"
            />
          </Col>
          <Col md={6} lg={4} className="work-card">
            <WorkCard
              imgPath={workImage3}
              title="Azure Project"
              description="Set up and automated fresh new Azure infrastructure for an external partner."
              readMoreLink="/work/stella-project"
              bgColor="#1e212d"
            />
          </Col>
          <Col md={6} lg={4} className="work-card">
            <WorkCard
              imgPath={workImage4}
              title="Migration to Kubernetes"
              description="Migrated from Docker Swarm to Kubernetes, solving numerous infrastructure issues."
              readMoreLink="/work/kube-migration"
              bgColor="#2d1e21"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Work;
