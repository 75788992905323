import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Particle from "../../Particle";

function ElasticSecurity() {
  const navigate = useNavigate();

  return (
    <Container fluid className="project-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={8}>
            <h1 className="project-heading" style={{ marginBottom: "30px" }}>
              Enhancing <strong className="purple">Elasticsearch Security</strong>
            </h1>
            <div style={{ color: "white", textAlign: "justify" }}>
              <p style={{ marginBottom: "20px" }}>
                The company I worked for relied heavily on Elasticsearch for monitoring purposes, with all services sending their logs to Logstash, which then forwarded them to Elasticsearch. However, the setup lacked essential security measures – no authentication and no SSL encryption. This posed significant risks, even though access was limited to the intranet.
              </p>
              
              <h2 className="purple" style={{ marginBottom: "20px" }}>Challenges</h2>
              <p style={{ marginBottom: "20px" }}>
                Securing the Elasticsearch environment meant addressing several challenges:
              </p>
              <ul style={{ marginBottom: "30px" }}>
                <li>Three clusters (dev, preprod, prod) needing security upgrades</li>
                <li>Multiple services connected without authentication or SSL</li>
              </ul>

              <h2 className="purple" style={{ marginBottom: "20px" }}>Approach</h2>
              <p style={{ marginBottom: "20px" }}>A comprehensive approach was undertaken to secure the monitoring infrastructure:</p>

              <h3 style={{ marginBottom: "15px" }}>1. Creation of Certificates</h3>
              <p style={{ marginBottom: "20px" }}>
                The team began by generating SSL certificates for secure communication. This involved:
              </p>
              <ul>
                <li><strong>Creating a Certificate Authority (CA):</strong> Established an internal CA to sign SSL certificates, ensuring trust across the infrastructure.</li>
                <li><strong>Generating Certificates for Each Node:</strong> Created individual certificates for each Elasticsearch node to secure intra-cluster communication.</li>
                <li><strong>Trust Configuration:</strong> Configured all relevant services to trust the CA, ensuring smooth, secure interactions.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>2. Adapting Continuous Deployment (CD) Code</h3>
              <p style={{ marginBottom: "20px" }}>
                Since the clusters were deployed using Jenkins, the continuous deployment (CD) scripts were adapted to incorporate the new security measures. This included:
              </p>
              <ul>
                <li><strong>Updating Deployment Scripts:</strong> Modified Jenkins pipelines to include SSL certificates and configure secure settings during deployment.</li>
                <li><strong>Configuration Files:</strong> Updated Elasticsearch configuration files to enable SSL and authentication, ensuring secure communication and access control.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>3. Load Balancer Configuration</h3>
              <p style={{ marginBottom: "20px" }}>
                The load balancers were updated to support SSL termination and reconfigured health checks to ensure compatibility with the new secure setup. This involved:
              </p>
              <ul>
                <li><strong>SSL Termination:</strong> Configured load balancers to handle SSL termination, decrypting incoming traffic and forwarding it securely to Elasticsearch nodes.</li>
                <li><strong>Health Checks:</strong> Adjusted health check configurations to work over HTTPS, ensuring continuous monitoring and high availability.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>4. Creation of Service Users and Permissions</h3>
              <p style={{ marginBottom: "20px" }}>
                A robust user management system was established by creating dedicated service accounts for each service interacting with Elasticsearch. Specific permissions were assigned to each account. Steps included:
              </p>
              <ul>
                <li><strong>Service Accounts:</strong> Created separate accounts for each service with tailored permissions, adhering to the principle of least privilege.</li>
                <li><strong>Role-Based Access Control (RBAC):</strong> Defined roles and permissions within Elasticsearch, ensuring that services only had access to necessary resources.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>5. Configuration of Kibana, Logstash, Heartbeat, and APM</h3>
              <p style={{ marginBottom: "20px" }}>
                Each component of the Elastic Stack was configured to work with the new security setup. This included:
              </p>
              <ul>
                <li><strong>Kibana:</strong> Updated Kibana configuration to use SSL and authenticate with Elasticsearch using service accounts.</li>
                <li><strong>Logstash:</strong> Modified Logstash pipelines to establish secure, authenticated connections with Elasticsearch.</li>
                <li><strong>Heartbeat and APM:</strong> Configured these components to send secure, authenticated data to Elasticsearch, ensuring comprehensive monitoring.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>6. Automated Credential Management</h3>
              <p style={{ marginBottom: "20px" }}>
                To streamline the process of credential creation, a Jenkins job was developed to allow users to generate their own credentials securely. This included:
              </p>
              <ul>
                <li><strong>Jenkins Job:</strong> Developed a Jenkins pipeline to automate the creation and distribution of credentials, reducing manual intervention and potential errors.</li>
                <li><strong>Secure Storage:</strong> Implemented secure storage mechanisms for managing and distributing credentials to ensure they remained confidential and protected.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>7. Comprehensive Testing</h3>
              <p style={{ marginBottom: "20px" }}>
                Extensive testing was conducted to ensure all services could communicate securely with the Elasticsearch cluster. This involved:
              </p>
              <ul>
                <li><strong>Functional Testing:</strong> Verified that all services functioned correctly with the new security measures in place.</li>
                <li><strong>Performance Testing:</strong> Conducted performance tests to ensure that the security enhancements did not negatively impact system performance.</li>
                <li><strong>Security Testing:</strong> Performed vulnerability assessments and penetration testing to validate the effectiveness of the new security measures.</li>
              </ul>

              <h3 style={{ marginBottom: "15px" }}>8. Documentation and Training</h3>
              <p style={{ marginBottom: "30px" }}>
                All changes were meticulously documented, and training sessions were provided for team members to ensure understanding and effective maintenance of the new security standards. This included:
              </p>
              <ul>
                <li><strong>Documentation:</strong> Created comprehensive documentation detailing the security changes, configurations, and procedures for future reference.</li>
                <li><strong>Training Sessions:</strong> Conducted training sessions to educate team members on the new security measures, best practices, and troubleshooting techniques.</li>
              </ul>

              <h2 className="purple" style={{ marginBottom: "20px" }}>Outcome</h2>
              <p style={{ marginBottom: "30px" }}>
                The project significantly enhanced the monitoring infrastructure's security. By implementing SSL and authentication, data transmission was secured, and only authorized services could access the Elasticsearch clusters. The successful completion of this project resulted in a robust, secure monitoring setup, protecting sensitive data and maintaining system integrity.
              </p>
            </div>
            <Button variant="primary" onClick={() => navigate(-1)} style={{ marginTop: "20px" }}>
              Back to Work
            </Button>
          </Col>
        </Row>
      </Container>
      <Particle />
    </Container>
  );
}

export default ElasticSecurity;
