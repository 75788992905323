import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/linkdein.png";
import Tilt from "react-parallax-tilt";
import Experiences from "../About/Experiences"


import {
  AiFillGithub,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <div className="about-card-container">
              <p className="home-about-body">
                Passionate SRE/DevOps engineer with 4 years of experience specializing in maintaining infrastructure availability and reliability.
                <br />
                <br />
                I am proficient in managing and scaling both cloud and bare-metal servers, and Kubernetes clusters, ensuring secure and reliable operations.
                <br />
                <br />
                My field of interest includes 
                <i>
                  <b className="purple"> Infrastructure as Code (IaC), CI/CD pipelines, and Monitoring solutions.</b>
                </i>
                <br />
                <br />
                I have experience working with technologies like 
                <i>
                  <b className="purple"> Docker, Terraform, Ansible, Azure, AWS, Jenkins, and GitHub Actions.</b>
                </i>
                <br />
                <br />
                I am always eager to learn and explore new technologies, with a particular interest in
                <i>
                  <b className="purple"> blockchain technology and its applications.</b>
                </i>
              </p>
            </div>
          </Col>
          <Col md={4} className="myAvtar">
              <img src={myImg} className="img-fluid rounded-img" alt="avatar" />
          </Col>
        </Row>
        <Row>
        <Row>
          <Col md={12} className="home-about-social">
            <div className="section-divider"></div>
            <h1 className="project-heading">
              <strong className="purple">Professional</strong> Background
            </h1>
          <Experiences />
          </Col>
        </Row>

          <Col md={12} className="home-about-social">
          <div className="section-divider"></div>

            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Yb2411"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/yassine-bergui-938547a0/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
