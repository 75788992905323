import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <div className="about-card-container">
      <Card className="quote-card-view">
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p>
              Hi Everyone, I am <span className="purple">Yassine Bergui </span>
              from <span className="purple">France.</span>
              <br />
              <br />
              I am a passionate <span className="purple">SRE/DevOps</span> engineer with 4 years of experience specializing in setting up infrastructure and tools to maintain availability and reliability.
              <br />
              I have completed my <span className="purple">Master's degree</span> in IT systems at SUP DE VINCI (Paris)
              <br />
              <br />
              Apart from working, some other activities that I love to do!
            </p>
            <ul>
              <li className="about-activity">
                <ImPointRight /> Playing Games
              </li>
              <li className="about-activity">
                <ImPointRight /> Cooking
              </li>
              <li className="about-activity">
                <ImPointRight /> Working out
              </li>
            </ul>
          </blockquote>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AboutCard;
