import React from "react";
import { Card, Button } from "react-bootstrap";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";

function ProjectCards(props) {
  const isLinkInProgress = !props.ghLink && !props.demoLink;

  return (
    <Card className="project-card-view" style={{ backgroundColor: props.bgColor, transition: 'all 0.3s ease-in-out' }}>
      <div className="image-container" style={{ height: "50%" }}>
        <Card.Img variant="top" src={props.imgPath} alt="card-img" style={{ width: "80%", height: "auto", objectFit: "cover" }} />
      </div>
      <Card.Body className="d-flex flex-column" style={{ height: "50%" }}>
        <Card.Title className="project-card-title">{props.title}</Card.Title>
        <Card.Text>{props.subtitle}</Card.Text>
        <Card.Text className="flex-grow-1" style={{ textAlign: "justify", overflowY: "auto" }}>
          {props.description}
        </Card.Text>
        <div className="mt-auto">
          {isLinkInProgress ? (
            <Button variant="warning" disabled style={{ color: "black", opacity: 1 }}>
              <FaSpinner className="spin" /> &nbsp; In Progress
            </Button>
          ) : (
            <>
              {props.ghLink && (
                <Button variant="primary" href={props.ghLink} target="_blank">
                  <BsGithub /> &nbsp;
                  {props.isBlog ? "Blog" : "GitHub"}
                </Button>
              )}
              {"\n"}
              {"\n"}
              {!props.isBlog && props.demoLink && (
                <Button
                  variant="primary"
                  href={props.demoLink}
                  target="_blank"
                  style={{ marginLeft: "10px" }}
                >
                  <CgWebsite /> &nbsp;
                  {"Website"}
                </Button>
              )}
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default React.memo(ProjectCards);
