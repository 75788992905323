import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Particle from "../../Particle";

function AzureProject() {
  const navigate = useNavigate();

  return (
    <Container fluid className="project-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={8}>
            <h1 className="project-heading" style={{ marginBottom: "30px" }}>
              <strong className="purple">Azure Project</strong>
            </h1>
            <div style={{ color: "white", textAlign: "justify" }}>
              <p style={{ marginBottom: "20px" }}>
                I worked on the Azure Project for a company I was employed at. This project involved collaborating with an external partner to establish a reliable and scalable environment on Azure for their trustworthiness assessment tool. This tool evaluates a company's reliability based on financial results. Our role was to set up the necessary infrastructure and automate the deployment across multiple environments.
              </p>
              
              <h2 className="purple" style={{ marginBottom: "20px" }}>Challenges</h2>
              <p style={{ marginBottom: "20px" }}>
                The project involved several key phases:
              </p>

              <h3 style={{ marginBottom: "15px" }}>1. Infrastructure Setup</h3>
              <p style={{ marginBottom: "20px" }}>
                Collaborated with the partner to set up databases, Kubernetes clusters, and monitoring systems on Azure. We ensured the infrastructure was robust and secure, adhering to best practices for cloud architecture.
              </p>

              <h3 style={{ marginBottom: "15px" }}>2. Automation with Terraform</h3>
              <p style={{ marginBottom: "20px" }}>
                Automated the infrastructure setup using Terraform, enabling consistent and repeatable deployments across development, pre-production, and production environments. This automation reduced manual errors and streamlined the deployment process.
              </p>

              <h3 style={{ marginBottom: "15px" }}>3. CI/CD Pipeline</h3>
              <p style={{ marginBottom: "20px" }}>
                Developed CI/CD pipelines utilizing GitHub, Jenkins, and ArgoCD to automate the build, test, and deployment processes. This ensured that new code changes were automatically tested and deployed, improving efficiency and reducing the time to market.
              </p>

              <h3 style={{ marginBottom: "15px" }}>4. Ongoing Maintenance</h3>
              <p style={{ marginBottom: "20px" }}>
                Provided continuous support to developers, optimized platform costs, and resolved performance issues related to MongoDB and cloud-based environments. This involved regular monitoring, troubleshooting, and optimization to maintain system reliability and performance.
              </p>

              <h3 style={{ marginBottom: "15px" }}>5. Performance Optimization</h3>
              <p style={{ marginBottom: "30px" }}>
                Identified and resolved performance issues, particularly with MongoDB settings and inefficient database requests. Conducted performance tuning and optimization to enhance database efficiency and application responsiveness.
              </p>

              <h2 className="purple" style={{ marginBottom: "20px" }}>Outcome</h2>
              <p style={{ marginBottom: "30px" }}>
                This project spanned over a year, primarily due to the partner's unfamiliarity with cloud-based environments. However, it ultimately resulted in a robust and scalable infrastructure that met the company's needs and significantly improved their operational efficiency and reliability.
              </p>
            </div>
            <Button variant="primary" onClick={() => navigate(-1)} style={{ marginTop: "20px" }}>
              Back to Work
            </Button>
          </Col>
        </Row>
      </Container>
      <Particle />
    </Container>
  );
}

export default AzureProject;
