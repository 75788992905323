import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ezlobby from "../../Assets/Projects/ezlobby.png";
import qrinstant from "../../Assets/Projects/qrinstant.png";
import littlemouse from "../../Assets/Projects/littlemouse.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      {/* Commenter cette ligne si vous n'utilisez pas Particle */}
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={littlemouse}
              isBlog={false}
              title="Little Mouse NFT"
              subtitle="2022"
              description={
                <>
                  A fun, non-profit project done with friends, deploying smart contracts on four different chains (omnichain) for a free NFT mint. We used the LayerZero libraries to enable cross-chain NFT transfers. Check out their work <a href="https://x.com/LayerZero_Labs" target="_blank" rel="noopener noreferrer">here</a>.
                </>
              }              demoLink="https://x.com/littlemousenft"
              bgColor="#1e212d"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ezlobby}
              isBlog={false}
              title="EZ Lobby"
              subtitle="2024"
              description="A personal project that allows Call of Duty players to change their in-game location using Smart DNS technology. This tool enhances the gaming experience by providing better matchmaking opportunities."
              ghLink="https://github.com/Yb2411/ezlobby"
              demoLink="https://ez-lobby.com/"
              bgColor="#1e212d"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={qrinstant}
              isBlog={false}
              title="QR Instant"
              subtitle="2024"
              description="An application to create custom QR codes and track related stats such as number of scans and source locations. It also allows users to customize their QR codes to increase engagement."
              ghLink=""
              demoLink=""
              bgColor="#2d1e21"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
