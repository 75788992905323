import React from "react";
import { Col, Row, Container } from "react-bootstrap";

function Toolstack() {
  return (
    <Container fluid className="experience-section">
      <Row>
      <Col md={6}>
          <div className="experience-row">
            <div className="experience-card">
            <h5>Master's degree: IT systems Expert</h5>
              <h6>SUP DE VINCI, COURBEVOIE - 92400</h6>
              <h6>2017 - 2019</h6>
            </div>
          </div>
          <div className="experience-row">
            <div className="experience-card">
            <h5>Bachelor's degree: IT Developer</h5>
              <h6>ESIEE-IT - CERGY - 95800</h6>
              <h6>2016 - 2017</h6>
            </div>
          </div>
          <div className="experience-row">
            <div className="experience-card">
            <h5>Associate Degree: IT Systems and Networks</h5>
              <h6>ESIEE-IT - PONTOISE - 95300</h6>
              <h6>2014 - 2016</h6>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="experience-row">
            <div className="experience-card">
              <h5>SRE / DevOps Engineer</h5>
              <h6>Société Générale (SGCIB)</h6>
              <h6>2019-2023</h6>
              <br />
              <ul>
                <li>Daily production operations / Platform administration</li>
                <li>Maintaining the production infrastructure in operational conditions</li>
                <li>Management of the VTOM scheduler: Creation of batches and jobs, resolution of daily errors</li>
                <li>Updating of documentary elements (procedures, supporting documents)</li>
                <li>Implementation of production monitoring (ELK, Grafana)</li>
                <li>Management and maintenance of applicative ELK clusters</li>
                <li>Maintained Kubernetes clusters in operational conditions</li>
                <li>Management of exceptional interventions (DR, Week-end-infra)</li>
                <li>Maintain and evolve basic technical components: Docker base images, Jenkins libraries, monitoring tools</li>
              </ul>
            </div>
          </div>
          <div className="experience-row">
            <div className="experience-card">
              <h5>Production & Systems Engineer</h5>
              <h6>METLIFE (Insurance company)</h6>
              <h6>2017-2019</h6>
              <br/>
              <ul>
                <li>FreeBSD 10 & Windows 2012 servers administration</li>
                <li>Configuration and installation of firewalls</li>
                <li>Deployments / Server Migrations</li>
                <li>Mail management, web servers, dns, ftp, samba</li>
                <li>Internal development (scripting / Web)</li>
                <li>Development of a Web interface: Creation of a To-do list / Reporting intranet site for the operations team</li>
              </ul>
            </div>
          </div>
          <div className="experience-row">
            <div className="experience-card">
            <h5>System and Network Administrator</h5>
              <h6>SimpleRezo (IT services company)</h6>
              <h6>2014-2017</h6>
              <br/>
              <ul>
                <li>Setting up a production environment on Azure</li>
                <li>Performing databases migrations / refreshes</li>
                <li>Windows 2003 to 2016 migration project</li>
                <li>Tier II Support</li>
                <li>Maintain and evolve basic technical components: Docker base images, Jenkins libraries, monitoring tools</li>
                <li>Management and maintenance of applicative ELK clusters</li>
                <li>Maintained Kubernetes clusters in operational conditions</li>
              </ul>
            </div>
          </div>
          </Col>
      </Row>
    </Container>
  );
}

export default Toolstack;
