import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

function WorkCard(props) {
  return (
    <Card className="project-card-view">
      <div className="image-container">
        <Card.Img variant="top" src={props.imgPath} alt="card-img" className="card-img" />
      </div>
      <div style={{ flex: 2 }}>
        <Card.Body>
          <Card.Title className="project-card-title">{props.title}</Card.Title>
          <Card.Text style={{ textAlign: "justify" }}>
            {props.description}
          </Card.Text>
          {props.readMoreLink ? (
            <Button variant="primary" as={Link} to={props.readMoreLink}>
              Read More
            </Button>
          ) : (
            <Button variant="warning" disabled style={{ color: "white", opacity: 1 }}>
              <FaSpinner className="spin" /> &nbsp; In Progress
            </Button>
          )}
        </Card.Body>
      </div>
    </Card>
  );
}

export default WorkCard;
