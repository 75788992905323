import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Particle from "../../Particle";

function DeployEthereumNode() {
  const navigate = useNavigate();

  return (
    <Container fluid className="project-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={8}>
            <h1 className="project-heading" style={{ marginBottom: "30px" }}>
              Deploying an <strong className="purple">Ethereum Node</strong> on AWS with Kubernetes
            </h1>
            <div style={{ color: "white", textAlign: "justify" }}>
              <p style={{ marginBottom: "20px" }}>
              For a personal challenge, I have decided to set up an Ethereum node on the Sepolia testnet to understand how it works and what the stakes are, such as the necessary resources and secret management.             
               </p>
            
              <h2 className="purple" style={{ marginBottom: "20px", fontWeight: "bold" }}>Prerequisites</h2>
              <p style={{ marginBottom: "20px" }}>
                - Clone this repo : https://github.com/Yb2411/ethereum-node-aws-kubernetes-guide<br />
                - AWS account<br />
                - kubectl and aws-cli installed and configured on your machine<br />
                - Terraform installed
              </p>

              <h2 className="purple" style={{ marginBottom: "20px", fontWeight: "bold" }}>Definitions</h2>

              <h3 className="sub-heading" style={{ marginBottom: "15px", fontWeight: "bold" }}>Geth</h3>
              <p style={{ marginBottom: "20px" }}>
                Geth (Go Ethereum) is a popular Ethereum client implemented in the Go programming language. It is used to run a full Ethereum node, enabling users to participate in the Ethereum network by validating transactions, executing smart contracts, and maintaining the blockchain's state. Geth allows developers and users to interact with the Ethereum network, deploy decentralized applications (dApps), and mine Ether (ETH).
              </p>

              <h3 className="sub-heading" style={{ marginBottom: "15px", fontWeight: "bold" }}>Prysm Beacon Chain</h3>
              <p style={{ marginBottom: "20px" }}>
                Prysm Beacon Chain is a client implementation for Ethereum 2.0 written in Go. The Beacon Chain is a central component of Ethereum 2.0, responsible for managing the consensus of the new Proof of Stake (PoS) protocol. It coordinates the network of shards and validators, processes cross-links, and ensures the overall security and synchronization of the blockchain. The Beacon Chain manages validators' activities, such as proposing and attesting blocks, and handles rewards and penalties.
              </p>

              <h3 className="sub-heading" style={{ marginBottom: "15px", fontWeight: "bold" }}>Prysm Validator</h3>
              <p style={{ marginBottom: "20px" }}>
                Prysm Validator is a client software that allows individuals to participate as validators in the Ethereum 2.0 network. Validators replace miners in the PoS consensus mechanism, staking their ETH to propose and attest to new blocks. The Prysm Validator client manages the validator's duties, including key management, signing blocks and attestations, and communicating with the Beacon Chain to ensure the integrity and security of the blockchain. Validators are rewarded with ETH for their participation and adherence to the protocol.
              </p>

              <h2 className="purple" style={{ marginBottom: "20px", fontWeight: "bold" }}>Steps to Deploy</h2>

              <h3 className="sub-heading" style={{ marginBottom: "15px", fontWeight: "bold" }}>1. Setting Up the Infrastructure with Terraform</h3>
              <p style={{ marginBottom: "20px" }}>
                The Terraform files provision an EKS cluster with 3 worker nodes (2 t3.medium and 1 t3.large). Navigate to the `terraform` directory and run:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  terraform init<br />
                  terraform apply
                </code>
              </pre>

              <h3 className="sub-heading" style={{ marginBottom: "15px", fontWeight: "bold" }}>2. Creating Kubernetes Secrets</h3>
              
              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>Validator Keys</h4>
              <p style={{ marginBottom: "20px" }}>
                Generate the validator keys following this <a href="https://github.com/ethereum/staking-deposit-cli?tab=readme-ov-file" target="_blank" rel="noopener noreferrer" className="purple">guide</a>. Don't forget the password. Then, create a Kubernetes secret:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  kubectl create secret generic validator-keys --from-file=validator_keys/
                </code>
              </pre>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>JWT Secret</h4>
              <p style={{ marginBottom: "20px" }}>
                Generate a JWT secret for authenticated communication between the Ethereum client and the Beacon Chain. Then create a Kubernetes secret:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  openssl rand -hex 32 | tr -d "\n" &gt; jwtsecret<br />
                  kubectl create secret generic jwtsecret --from-file=jwtsecret
                </code>
              </pre>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>Wallet Password</h4>
              <p style={{ marginBottom: "20px" }}>
                Create a file wallet-password.txt containing the password you will use to secure your Prysm wallet. Then, create a Kubernetes secret:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  kubectl create secret generic wallet-password --from-file=wallet-password.txt
                </code>
              </pre>
              <p style={{ marginBottom: "20px" }}>
                Note: In this example, we use the same password for both the wallet and the validator. This must NOT be done in a production environment. It has been done here only due to laziness.
              </p>

              <h3 className="sub-heading" style={{ marginBottom: "15px", fontWeight: "bold" }}>3. Deploying the Ethereum Node and Prysm Services</h3>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>Deploy Geth</h4>
              <p style={{ marginBottom: "20px" }}>
                Apply the Geth deployment:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  kubectl apply -f kubernetes/geth-deployment.yml
                </code>
              </pre>
              <p style={{ marginBottom: "20px" }}>
                Check the logs to ensure Geth is running properly:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  kubectl logs -f &lt;geth-pod-name&gt;
                </code>
              </pre>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>Deploy Prysm Beacon Chain</h4>
              <p style={{ marginBottom: "20px" }}>
                Apply the Prysm Beacon Chain deployment:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  kubectl apply -f kubernetes/prysm-beacon-deployment.yml
                </code>
              </pre>
              <p style={{ marginBottom: "20px" }}>
                Check the logs to ensure the Beacon Chain is running properly:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  kubectl logs -f &lt;beacon-chain-pod-name&gt;
                </code>
              </pre>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>Deploy Prysm Validator</h4>
              <p style={{ marginBottom: "20px" }}>
                Apply the Prysm Validator deployment:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  kubectl apply -f kubernetes/prysm-validator-deployment.yml
                </code>
              </pre>
              <p style={{ marginBottom: "20px" }}>
                Check the logs to ensure the Validator is running properly:
              </p>
              <pre className="code-block" style={{ marginBottom: "20px" }}>
                <code>
                  kubectl logs -f &lt;validator-pod-name&gt;
                </code>
              </pre>

              <h3 className="sub-heading" style={{ marginBottom: "15px", fontWeight: "bold" }}>4. Monitoring and Adjustments</h3>
              <p style={{ marginBottom: "20px" }}>
                In this proof of concept, a 10GB storage was used, which is not enough to have a fully sync node. For a production deployment, consider the following:
              </p>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>Monitoring</h4>
              <ul style={{ marginBottom: "20px" }}>
                <li>At least 1 TB of storage.</li>
                <li>Implement comprehensive monitoring tools (Prometheus, Grafana).</li>
                <li>Set up centralized logging (ELK Stack, Fluentd).</li>
                <li>Configure alerts for resource usage and node failures.</li>
              </ul>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>Security</h4>
              <ul style={{ marginBottom: "20px" }}>
                <li>Implement network security policies.</li>
                <li>Use Kubernetes RBAC for access control.</li>
                <li>Manage secrets securely (Kubernetes Secrets, HashiCorp Vault).</li>
              </ul>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>High Availability</h4>
              <ul style={{ marginBottom: "20px" }}>
                <li>Deploy nodes across multiple availability zones.</li>
                <li>Implement load balancing (Kubernetes LoadBalancer, ingress controller).</li>
              </ul>

              <h4 className="sub-sub-heading" style={{ marginBottom: "15px" }}>Access Control</h4>
              <ul style={{ marginBottom: "30px" }}>
                <li>Implement strict access control policies.</li>
              </ul>

              <h2 className="purple" style={{ marginBottom: "20px", fontWeight: "bold" }}>Conclusion</h2>
              <p style={{ marginBottom: "30px" }}>
                By following these steps, you have successfully deployed an Ethereum node, a Beacon Chain, and a Validator on AWS using Kubernetes. This setup demonstrates the basic concept and can be scaled according to your requirements. For further information and troubleshooting, refer to the official documentation of <a href="https://geth.ethereum.org/docs" target="_blank" rel="noopener noreferrer" className="purple">Geth</a>, <a href="https://docs.prylabs.network/docs/getting-started" target="_blank" rel="noopener noreferrer" className="purple">Prysm</a>, and <a href="https://kubernetes.io/docs/home/" target="_blank" rel="noopener noreferrer" className="purple">Kubernetes</a>.
              </p>
            </div>
            <Button variant="primary" onClick={() => navigate(-1)} style={{ marginTop: "20px" }}>
              Back to Work
            </Button>
          </Col>
        </Row>
      </Container>
      <Particle />
    </Container>
  );
}

export default DeployEthereumNode;
