import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsFiletypeSql } from "react-icons/bs";
import { FaNetworkWired } from "react-icons/fa";


import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiPython,
  DiGit,
  DiAws,
  DiJenkins,
  DiDocker,
  DiLinux,
  DiGrafana,
  DiVault,
  DiNginx
} from "react-icons/di";
import {
  SiKubernetes,
  SiTerraform,
  SiAnsible,
  SiAzuredevops,
  SiGithubactions,
  SiElasticsearch,
  SiLinux,
  SiGrafana,
  SiVault,
  SiNginx
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <DiLinux />
          <p>Linux</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <DiDocker />
          <p>Docker</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiKubernetes />
          <p>Kubernetes</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiTerraform />
          <p>Terraform</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiAnsible />
          <p>Ansible</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiAzuredevops />
          <p>Azure</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <DiAws />
          <p>AWS</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <DiJenkins />
          <p>Jenkins</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiGithubactions />
          <p>GitHub Actions</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <DiGit />
          <p>Git</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiElasticsearch />
          <p>Elasticsearch</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiGrafana />
          <p>Grafana</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiVault />
          <p>Vault</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <SiNginx />
          <p>Nginx</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
        <BsFiletypeSql />
          <p>SQL</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
          <DiPython />
          <p>Python / Scripting</p>
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div className="icon-container">
        <FaNetworkWired />
          <p>Networking</p>
        </div>
      </Col>
    </Row>
  );
}

export default Techstack;
